import React from 'react';
import WizardStep from '../wizard-step';
import VulnerabilityQuestionnaire from '../personal/vulnerability-questionnaire';

import { ProposalStepProps } from '../types/ProposalStepProps';

const VulnerabilityStep: React.FC<ProposalStepProps> = ({ id, stepNumber, formData, onBack, onForward }) => {
    return (
        <WizardStep id={id} stepNumber={stepNumber} onBack={onBack} onForward={onForward}>
            <VulnerabilityQuestionnaire formData={formData} />
        </WizardStep>
    );
}

export default VulnerabilityStep;