import React, { useState, useEffect } from 'react';
import { useFormContext } from "react-hook-form";
import ErrorMessage from '../../layout/forms/error-message';
interface IProps {
    formData: any;
}

//TODO: replacesCurrentCar - do we need validation there like for other radio buttons?
const VulnerabilityQuestionnaire: React.FC<IProps> = ({ formData }) => {
    const [userIsVulnerable, setUserIsUlnerable] = useState(false);
    const { register, watch, formState: { errors } } = useFormContext();
    
    const watchHealthIssues = watch("hasHealthIssues", formData.hasHealthIssues);
    const watchApplicationVulnerability = watch("hasApplicationVulnerability", formData.hasApplicationVulnerability);
    const watchPaymentVulnerability = watch("hasPaymentVulnerability", formData.hasPaymentVulnerability);
    const watchLifeEvent = watch("hasLifeEvent", formData.hasLifeEvent);
    const watchCircumstances = watch("hasCircumstances", formData.hasCircumstances);
    const watchFutureCircumstances = watch("hasFutureCircumstances", formData.hasFutureCircumstances);
    const watchContractAgreed = watch("hasContractAgreed", formData.hasContractAgreed);
    const watchNeedsHelp = watch("needsHelp", formData.needsHelp);

    useEffect(() => {
        if (watchPaymentVulnerability === "yes" || watchLifeEvent === "yes" || watchContractAgreed === "no" || watchNeedsHelp === "yes") {
            setUserIsUlnerable(true);
        } else {
            setUserIsUlnerable(false);
        }
    }, [watchHealthIssues, watchApplicationVulnerability, watchPaymentVulnerability, watchLifeEvent, watchCircumstances, watchFutureCircumstances, watchContractAgreed, watchNeedsHelp]);

    return (
        <>
            <div className="row">
                <div className="col-24">
                    <h2 className="w-100 d-block h3 mb-3å">Vulnerability Questionnaire</h2>
                    <p className="w-100 d-block mb-0 fw-bold">To ensure all our customers are treated fairly we are required by our regulator (The FCA) to ask you the following questions:</p>
                    <hr className="p-0 my-3 my-md-4" />
                </div>
            </div>
            <div className="row">
                <div className={`col-24 ${watchHealthIssues === "yes" ? "mb-4" : ""}`}>
                    <label className="w-100 d-block mb-2">1a: Have you had any health issues which you feel may affect your ability to make decisions when taking out this lease or that may effect your ability to make the payments?</label>
                    <div className="form-check form-check-inline mt-2 mb-1">
                        <input type="radio" value="no" id="hasHealthIssues_no" defaultChecked={formData.hasHealthIssues === 'no'}
                            {...register("hasHealthIssues", {required: true})} className={`form-check-input ${errors.hasHealthIssues ? "is-invalid" : ""}`}
                        />
                        <label className="form-check-label d-block pe-4" htmlFor="hasHealthIssues_no">No</label>
                    </div>
                    <div className="form-check form-check-inline mt-2 mb-1">
                        <input type="radio" value="yes" id="hasHealthIssues_yes" defaultChecked={formData.hasHealthIssues === 'yes'}
                            {...register("hasHealthIssues", {required: true})} className={`form-check-input ${errors.hasHealthIssues ? "is-invalid" : ""}`}
                        />
                        <label className="form-check-label d-block pe-4" htmlFor="hasHealthIssues_yes">Yes</label>
                    </div>
                    <ErrorMessage errors={errors} name="hasHealthIssues" />
                    {watchHealthIssues !== "yes" && <hr className="p-0 my-3 my-md-4" />}
                </div>
                {watchHealthIssues === "yes" && (
                    <>
                        <div className="col-24 px-4 px-md-5 mb-3">
                            <label className="w-100 d-block mb-2">1b: Does your health condition make you vulnerable while you are completing the application process for the lease?</label>
                            <div className="form-check form-check-inline mt-2 mb-1">
                                <input type="radio" value="no" id="hasApplicationVulnerability_no" defaultChecked={formData.hasApplicationVulnerability === 'no'}
                                    {...register("hasApplicationVulnerability", {required: true})} className={`form-check-input ${errors.hasApplicationVulnerability ? "is-invalid" : ""}`}
                                />
                                <label className="form-check-label d-block pe-4" htmlFor="hasApplicationVulnerability_no">No</label>
                            </div>
                            <div className="form-check form-check-inline mt-2 mb-1">
                                <input type="radio" value="yes" id="hasApplicationVulnerability_yes" defaultChecked={formData.hasApplicationVulnerability === 'yes'}
                                    {...register("hasApplicationVulnerability", {required: true})} className={`form-check-input ${errors.hasApplicationVulnerability ? "is-invalid" : ""}`}
                                />
                                <label className="form-check-label d-block pe-4" htmlFor="hasApplicationVulnerability_yes">Yes</label>
                            </div>
                            <ErrorMessage errors={errors} name="hasApplicationVulnerability" />
                        </div>
                        {watchApplicationVulnerability === "yes" && (
                            <>
                                <div className="col-24 px-4 px-md-5 mb-3">
                                    <label className="w-100 d-block mb-2">1c: Does your health condition affect your ability to make payments?</label>
                                    <div className="form-check form-check-inline mt-2 mb-1">
                                        <input type="radio" value="no" id="hasPaymentVulnerability_no" defaultChecked={formData.hasPaymentVulnerability === 'no'}
                                            {...register("hasPaymentVulnerability", {required: true})} className={`form-check-input ${errors.hasPaymentVulnerability ? "is-invalid" : ""}`}
                                        />
                                        <label className="form-check-label d-block pe-4" htmlFor="hasPaymentVulnerability_no">No</label>
                                    </div>
                                    <div className="form-check form-check-inline mt-2 mb-1">
                                        <input type="radio" value="yes" id="hasPaymentVulnerability_yes" defaultChecked={formData.hasPaymentVulnerability === 'yes'}
                                            {...register("hasPaymentVulnerability", {required: true})} className={`form-check-input ${errors.hasPaymentVulnerability ? "is-invalid" : ""}`}
                                        />
                                        <label className="form-check-label d-block pe-4" htmlFor="hasPaymentVulnerability_yes">Yes</label>
                                    </div>
                                    <ErrorMessage errors={errors} name="hasPaymentVulnerability" />
                                </div>
                            </>
                        )}
                    </>
                )}

                {((watchHealthIssues === "no") ||  (watchHealthIssues === "yes" && watchApplicationVulnerability === "no") || (watchHealthIssues === "yes" && watchApplicationVulnerability === "yes" && watchPaymentVulnerability === "no")) && (
                    <div className="col-24">
                        {watchHealthIssues === "yes" && <hr className="p-0 my-3 my-md-4" />}
                        <label className="w-100 d-block mb-2">2a: Have you experienced a life event that may effect your ability to make decisions when taking out this lease or that may effect your ability to make the payments?</label>
                        <div className="form-check form-check-inline mt-2 mb-1">
                            <input type="radio" value="no" id="hasLifeEvent_no" defaultChecked={formData.hasLifeEvent === 'no'}
                                {...register("hasLifeEvent", {required: true})} className={`form-check-input ${errors.hasLifeEvent ? "is-invalid" : ""}`}
                            />
                            <label className="form-check-label d-block pe-4" htmlFor="hasLifeEvent_no">No</label>
                        </div>
                        <div className="form-check form-check-inline mt-2 mb-1">
                            <input type="radio" value="yes" id="hasLifeEvent_yes" defaultChecked={formData.hasLifeEvent === 'yes'}
                                {...register("hasLifeEvent", {required: true})} className={`form-check-input ${errors.hasLifeEvent ? "is-invalid" : ""}`}
                            />
                            <label className="form-check-label d-block pe-4" htmlFor="hasLifeEvent_yes">Yes</label>
                        </div>
                        <ErrorMessage errors={errors} name="hasLifeEvent" />
                        <hr className="p-0 my-3 my-md-4" />
                    </div>
                )}

                {(watchLifeEvent === "no") && (
                    <div className="col-24">
                        <label className="w-100 d-block mb-2">3a: Are you aware of any personal circumstances or vulnerabilities that may effect your ability to keep up the payments on a lease?</label>
                        <div className="form-check form-check-inline mt-2 mb-1">
                            <input type="radio" value="no" id="hasCircumstances_no" defaultChecked={formData.hasCircumstances === 'no'}
                                {...register("hasCircumstances", {required: true})} className={`form-check-input ${errors.hasCircumstances ? "is-invalid" : ""}`}
                            />
                            <label className="form-check-label d-block pe-4" htmlFor="hasCircumstances_no">No</label>
                        </div>
                        <div className="form-check form-check-inline mt-2 mb-1">
                            <input type="radio" value="yes" id="hasCircumstances_yes" defaultChecked={formData.hasCircumstances === 'yes'}
                                {...register("hasCircumstances", {required: true})} className={`form-check-input ${errors.hasCircumstances ? "is-invalid" : ""}`}
                            />
                            <label className="form-check-label d-block pe-4" htmlFor="hasCircumstances_yes">Yes</label>
                        </div>
                        <ErrorMessage errors={errors} name="hasCircumstances" />
                        <hr className="p-0 my-3 my-md-4" />
                    </div>
                )}

                {(watchCircumstances === "yes" || watchCircumstances === "no") && (
                    <div className="col-24">
                        <label className="w-100 d-block mb-2">4a: Are you aware/expecting any changes in your circumstances which may effect your ability  to keep up the payments on a lease?</label>
                        <div className="form-check form-check-inline mt-2 mb-1">
                            <input type="radio" value="no" id="hasFutureCircumstances_no" defaultChecked={formData.hasFutureCircumstances === 'no'}
                                {...register("hasFutureCircumstances", {required: true})} className={`form-check-input ${errors.hasFutureCircumstances ? "is-invalid" : ""}`}
                            />
                            <label className="form-check-label d-block pe-4" htmlFor="hasFutureCircumstances_no">No</label>
                        </div>
                        <div className="form-check form-check-inline mt-2 mb-1">
                            <input type="radio" value="yes" id="hasFutureCircumstances_yes" defaultChecked={formData.hasFutureCircumstances === 'yes'}
                                {...register("hasFutureCircumstances", {required: true})} className={`form-check-input ${errors.hasFutureCircumstances ? "is-invalid" : ""}`}
                            />
                            <label className="form-check-label d-block pe-4" htmlFor="hasFutureCircumstances_yes">Yes</label>
                        </div>
                        <ErrorMessage errors={errors} name="hasFutureCircumstances" />
                        <hr className="p-0 my-3 my-md-4" />
                    </div>
                )}

                {(watchFutureCircumstances === "yes" || watchFutureCircumstances === "no") && (
                    <>
                        <div className={`col-24 ${watchContractAgreed === "yes" ? "mb-4" : ""}`}>
                            <label className="w-100 d-block mb-2">5a: We want to ensure we provide the right methods of communication and offer appropriate support and guidance to all of our customers. Do you understand the contract you are entering into?</label>
                            <div className="form-check form-check-inline mt-2 mb-1">
                                <input type="radio" value="no" id="hasContractAgreed_no" defaultChecked={formData.hasContractAgreed === 'no'}
                                    {...register("hasContractAgreed", {required: true})} className={`form-check-input ${errors.hasContractAgreed ? "is-invalid" : ""}`}
                                />
                                <label className="form-check-label d-block pe-4" htmlFor="hasContractAgreed_no">No</label>
                            </div>
                            <div className="form-check form-check-inline mt-2 mb-1">
                                <input type="radio" value="yes" id="hasContractAgreed_yes" defaultChecked={formData.hasContractAgreed === 'yes'}
                                    {...register("hasContractAgreed", {required: true})} className={`form-check-input ${errors.hasContractAgreed ? "is-invalid" : ""}`}
                                />
                                <label className="form-check-label d-block pe-4" htmlFor="hasContractAgreed_yes">Yes</label>
                            </div>
                            <ErrorMessage errors={errors} name="hasContractAgreed" />
                        </div>
                        {watchContractAgreed == "yes" && 
                            <div className="col-24 px-4 px-md-5">
                                <label className="w-100 d-block mb-2">5b: Do you require any additional guidance or support with your lease?</label>
                                <div className="form-check form-check-inline mt-2 mb-1">
                                    <input type="radio" value="no" id="needsHelp_no" defaultChecked={formData.needsHelp === 'no'}
                                        {...register("needsHelp", {required: true})} className={`form-check-input ${errors.needsHelp ? "is-invalid" : ""}`}
                                    />
                                    <label className="form-check-label d-block pe-4" htmlFor="needsHelp_no">No</label>
                                </div>
                                <div className="form-check form-check-inline mt-2 mb-1">
                                    <input type="radio" value="yes" id="needsHelp_yes" defaultChecked={formData.needsHelp === 'yes'}
                                        {...register("needsHelp", {required: true})} className={`form-check-input ${errors.needsHelp ? "is-invalid" : ""}`}
                                    />
                                    <label className="form-check-label d-block pe-4" htmlFor="needsHelp_yes">Yes</label>
                                </div>
                                <ErrorMessage errors={errors} name="needsHelp" />
                            </div>
                        }
                    </>
                )}

                {userIsVulnerable &&
                    <div className="alert alert-success rounded-3 mt-3">Thank you. Your Account Manager will need to discuss this further with you. You can continue to the next step.</div>
                }
            </div>
        </>
    );
}

export default VulnerabilityQuestionnaire;